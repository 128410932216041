import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    { text: "When are eligibility determinations due?", value: "When are eligibility determinations due?" },
    { text: "Can DVR staff access Barcode system records?", value: "Can DVR staff access Barcode system records?" },
    { text: "What has changed in the RSA-911?", value: "What has changed in the RSA-911?" },
    { text: "What is the definition of competitive employment?", value: "What is the definition of competitive employment?" }

];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList} aria-label="Sample Questions">
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};

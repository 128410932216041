import { Outlet, NavLink, Link } from "react-router-dom";

import oilogo from "../../assets/OILogo.png";
/* import customerlogo from "../../assets/DSHS-DVR-Logo.png"; */
import customerlogo from "../../assets/DSHSLogo.jpg";

import styles from "./Layout.module.css";

import { useLogin } from "../../authConfig"

import { LoginButton } from "../../components/LoginButton"

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="https://www.outlookinsight.com" className={styles.headerTitleContainer}>
                        <img src={oilogo} aria-label="Link to Outlook Insight website" className={styles.outlookInsightLogo} />
                        <h4 className={styles.headerTitle}>Outlook Insight<br/>Knowledge Interpreter</h4>
                    </Link>
                         <div className={styles.headerIndexUpdated}>
                            <h5 className={styles.chatEmptyStateIndexCount}>4,531 Pages Indexed<br/>Last Updated 8/8/2024</h5>
                        </div>
                    <Link to="https://dshs.wa.gov/dvr" className={styles.headerTitleContainer}>
                        <h4 className={styles.headerRightText}>WA DSHS<br/>Division of Vocational Rehabilitation</h4>
                        <img src={customerlogo} aria-label="Link to DVR website" className={styles.customerLogo} />
                    </Link>
                    {useLogin && <LoginButton/>}
                </div>
            </header>

            <Outlet />
            {/*
            <footer id="bottom"></footer>
            Outlook Insight ◦ Modern Technology™ */}
        </div>
    );
};

export default Layout;
